export const DS_FLOOR_OPTIONS = [
  {
    key: -3,
    label: 'order-form.step4.box.delivery-customer.floor-u3',
  },
  {
    key: -2,
    label: 'order-form.step4.box.delivery-customer.floor-u2',
  },
  {
    key: -1,
    label: 'order-form.step4.box.delivery-customer.floor-u1',
  },
  {
    key: 0,
    label: 'order-form.step4.box.delivery-customer.floor-0',
  },
  {
    key: 1,
    label: 'order-form.step4.box.delivery-customer.floor-1',
  },
  {
    key: 2,
    label: 'order-form.step4.box.delivery-customer.floor-2',
  },
  {
    key: 3,
    label: 'order-form.step4.box.delivery-customer.floor-3',
  },
  {
    key: 4,
    label: 'order-form.step4.box.delivery-customer.floor-4',
  },
  {
    key: 5,
    label: 'order-form.step4.box.delivery-customer.floor-5',
  },
  {
    key: 6,
    label: 'order-form.step4.box.delivery-customer.floor-6',
  },
  {
    key: 7,
    label: 'order-form.step4.box.delivery-customer.floor-7',
  },
  {
    key: 8,
    label: 'order-form.step4.box.delivery-customer.floor-8',
  },
  {
    key: 9,
    label: 'order-form.step4.box.delivery-customer.floor-9',
  },
  {
    key: 10,
    label: 'order-form.step4.box.delivery-customer.floor-10',
  },
  {
    key: 11,
    label: 'order-form.step4.box.delivery-customer.floor-11',
  },
  {
    key: 12,
    label: 'order-form.step4.box.delivery-customer.floor-12',
  },
  {
    key: 13,
    label: 'order-form.step4.box.delivery-customer.floor-13',
  },
  {
    key: 14,
    label: 'order-form.step4.box.delivery-customer.floor-14',
  },
  {
    key: 15,
    label: 'order-form.step4.box.delivery-customer.floor-15',
  },
  {
    key: 16,
    label: 'order-form.step4.box.delivery-customer.floor-16',
  },
  {
    key: 17,
    label: 'order-form.step4.box.delivery-customer.floor-17',
  },
  {
    key: 18,
    label: 'order-form.step4.box.delivery-customer.floor-18',
  },
  {
    key: 19,
    label: 'order-form.step4.box.delivery-customer.floor-19',
  },
  {
    key: 20,
    label: 'order-form.step4.box.delivery-customer.floor-20',
  },
  {
    key: 21,
    label: 'order-form.step4.box.delivery-customer.floor-21',
  },
  {
    key: 22,
    label: 'order-form.step4.box.delivery-customer.floor-22',
  },
  {
    key: 23,
    label: 'order-form.step4.box.delivery-customer.floor-23',
  },
  {
    key: 24,
    label: 'order-form.step4.box.delivery-customer.floor-24',
  },
  {
    key: 25,
    label: 'order-form.step4.box.delivery-customer.floor-25',
  },
  {
    key: 26,
    label: 'order-form.step4.box.delivery-customer.floor-26',
  },
  {
    key: 27,
    label: 'order-form.step4.box.delivery-customer.floor-27',
  },
  {
    key: 28,
    label: 'order-form.step4.box.delivery-customer.floor-28',
  },
  {
    key: 29,
    label: 'order-form.step4.box.delivery-customer.floor-29',
  },
  {
    key: 30,
    label: 'order-form.step4.box.delivery-customer.floor-30',
  },
];
