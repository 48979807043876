import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const customerAddressValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const deliveryType = control.get('delivery_type');
  const specifyLater = control.get('specify_customer_data_later');
  const requiredFieldsCustomer = [
    'customer_name_delivery',
    'customer_street_delivery',
    'customer_zip_delivery',
    'customer_city_delivery',
    'customer_country_delivery',
    'customer_tel_delivery',
    'customer_floor_delivery',
  ];
  const requiredFieldsWarehouse = [
    'warehouse_address_retail',
    'warehouse_address',
    'warehouse_floor',
    'warehouse_zip',
    'warehouse_city',
    'warehouse_country',
  ];
  resetErrors(control, requiredFieldsCustomer);
  resetErrors(control, requiredFieldsWarehouse);
  if (!deliveryType || !specifyLater) {
    return null;
  }
  if (deliveryType.value === 'delivery_retailer') {
    setErrorsIfEmpty(control, requiredFieldsWarehouse);
  }
  if (
    specifyLater.value === false &&
    (deliveryType.value === 'delivery_customer' ||
      deliveryType.value === 'delivery_customer_montage')
  ) {
    setErrorsIfEmpty(control, requiredFieldsCustomer);
  }
  return null;
};

function resetErrors(control: AbstractControl, fields: string[]): void {
  fields.forEach((field) => {
    const controlField = control.get(field);
    if (controlField) {
      controlField.setErrors(null);
    }
  });
}

function setErrorsIfEmpty(control: AbstractControl, fields: string[]): void {
  fields.forEach((field) => {
    const controlField = control.get(field);
    if (controlField && !controlField.value && controlField.value !== 0) {
      controlField.setErrors({ required: true });
    }
  });
}
