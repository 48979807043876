import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { enableProdMode } from '@angular/core';
import { DS_ENVIRONMENT } from '@ds-environment';
import { AppModule } from './app/app.module';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dr_cookiebanner_options: {
      gtmInit: () => void;
    };
    dr_revokeChoice: () => void;
  }
}

if (DS_ENVIRONMENT.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
